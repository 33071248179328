import React from "react";
import { Layout } from "antd";


const { Footer } = Layout;

function AppFooter(){

    return (
        <Footer className="footer-links" style={{backgroundColor:"#2f4f4f", color:"#FFFFFF", marginTop:25}}>
            <center>
              <a href="/static/aboutus">About</a> | <a href="/static/privacy">Privacy</a> | <a href="/static/termsandcondition">Terms</a> | <a href="/contact">Contact</a>
            </center>
        </Footer>
    );

}

export default AppFooter;
