import { useState, useEffect, React } from "react";
import firebaseApp from "./Firebase";
import { Select, Tooltip, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import { Selector, AutoCenter, Toast, Card, Loading, ImageUploader, Image, TextArea } from "antd-mobile";
import AppFooter from "./AppFooter"
import Helper from "./Helper"
import { EmailIcon, EmailShareButton, PinterestShareButton, LinkedinShareButton, TumblrShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import localStore from "store2"


const { SubMenu } = Menu;
const { Header, Footer, Sider, Content } = Layout;

let count = 0;
let userId = "";


function Profile() {

  let [profileUpdated, setProfileUpdated] = useState(false);
  let [userProfileProps, setUserProfileProps] = useState({ "default": true, "image": "https://storage.googleapis.com/expertsq-public-files/profiles/default-profile.jpeg" });



  function openProfileById(){
    window.location.href = "/profile/" + userId;
  }


  function uploadProfile(fileObject){
    const uploadedPath = firebaseApp.uploadProfile(fileObject, userId);
    console.log(uploadedPath);
    uploadedPath.then((doc) => {
      console.log(doc);
      userProfileProps["image"] = doc["url"];
    });
    return uploadedPath;
  }


  function setFieldValue(fieldName, e)
  {
    console.log(fieldName, e.target.value);
    userProfileProps[fieldName] = e.target.value;
    setProfileUpdated(false);
  }

  function setDescriptionValue(fieldName, e)
  {
    console.log(fieldName, e);
    userProfileProps[fieldName] = e;
    setProfileUpdated(false);
  }

  function setFieldValueSelect(fieldName, e)
  {
    userProfileProps[fieldName] = e;
    console.log(userProfileProps[fieldName]);
    setProfileUpdated(false);
  }

  function setGenderValue(fieldName, e)
  {
    userProfileProps[fieldName] = e[0];
    console.log(userProfileProps[fieldName]);
  }


  function changeZipCodes(e){
    userProfileProps["zip"] = e;
    setProfileUpdated(false);
  }

  function updateUserProfile(){
    let search = [];
    if(userProfileProps["g"]!==undefined)
    {
      userProfileProps["g"] = (userProfileProps["g"] * 1);
      search.push("G"+userProfileProps["g"]);
    }
    if(userProfileProps["year"]!==undefined)
    {
      userProfileProps["year"] = (userProfileProps["year"] * 1);
      search.push("BY"+userProfileProps["year"]);
    }
    
    if(userProfileProps["gi"]!==undefined)
    {
      userProfileProps["gi"].forEach(function(part, index) { userProfileProps["gi"][index] = (part * 1); search.push("GI"+part); });
    }
    if(userProfileProps["hobbies"]!==undefined)
    {
      userProfileProps["hobbies"].forEach(function(part, index) { userProfileProps["hobbies"][index] = (part * 1); search.push("HOBBI"+part); });
    }
    if(userProfileProps["zip"]!==undefined)
    {
      userProfileProps["zip"].forEach(function(part, index) { search.push("ZIP"+part); });
    }
    userProfileProps["search"] = search;

    console.log("userProfileProps", userProfileProps);
    userProfileProps["user_id"] = userId;
    firebaseApp.updateUserProfile(userId, userProfileProps);

    localStore.set("expertsqUserId", userId);
    localStore.set("expertsqUserProfile", userProfileProps);
    setProfileUpdated(true);
  }

  useEffect(() => {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    const findProfileByIdQuery = firebaseApp.findProfileById(userId);
    showProfileById(findProfileByIdQuery);
  }, []);

  function showProfileById(docPromise){
    docPromise.then((doc) => {
      if (!doc.exists) { return; }
      userProfileProps = doc.data();
      userProfileProps["id"] = doc.id;

      if(userProfileProps["image"]===undefined)
      {
        userProfileProps["image"] = "https://storage.googleapis.com/expertsq-public-files/profiles/default-profile.jpeg";
      }
      userProfileProps["g"] = userProfileProps["g"] + "";
      
      if(userProfileProps["gi"]===undefined) userProfileProps["gi"] = ["1","2","3"];
      if(userProfileProps["hobbies"]===undefined) userProfileProps["hobbies"] = ["25", "29"];
      
      userProfileProps["gi"].forEach(function(part, index) { userProfileProps["gi"][index] = part + "" });
      userProfileProps["hobbies"].forEach(function(part, index) { userProfileProps["hobbies"][index] = part + "" });
      console.log(userProfileProps);
      userProfileProps["default"] = false;
      setUserProfileProps(userProfileProps);
    });
  }

  function sendHomePage()
  {
    window.location.href = "/";
  }

  function viewProfile()
  {
    window.location.href = "/profile/" + userId;
  }


    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{ backgroundColor: "#2f4f4f", paddingLeft: 0, position: "fixed", zIndex: 1, width: "100%" }}>
            <SideMenu />
          </Header>
          <Content style={{ backgroundColor: "#FFFFFF", marginTop: 60, marginRight: 20 }}>
            {!userProfileProps["default"] ? (
              <div>
                <Card key="profile-picture" title="Profile Picture">
                  <ImageUploader maxCount={1} upload={uploadProfile}>
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        borderRadius: 20,
                        backgroundImage: "url(" + userProfileProps["image"] + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        display: 'flex',
                        alignItems: 'top',
                        textAlign: "right",
                        color: '#ff00ff',
                      }}>
                      <i style={{ fontSize: 20 }} className="fas fa-marker"></i>
                    </div>
                  </ImageUploader>
                </Card>

                <Card key="name" title="Your Name">
                  <Input
                    placeholder='Name'
                    defaultValue={userProfileProps["name"]}
                    onChange={v => setFieldValue("name", v)}
                  />
                </Card>

                <Card key="year" title="Year of Birth">
                  <Input
                    placeholder='Year'
                    defaultValue={userProfileProps["year"]}
                    onChange={v => setFieldValue("year", v)}
                    maxLength={4}
                  />
                </Card>


                <Card key="description" title="About">
                  <TextArea
                    placeholder="Tell us about yourself"
                    defaultValue={userProfileProps["desc"]}
                    onChange={v => setDescriptionValue("desc", v)}
                    maxLength={30}
                  />
                </Card>


                <Card key="gender" title="Your Gender">
                  <Selector style={{ '--checked-color': '#ffe2e5' }}
                    options={Helper.findGenderOptions()} defaultValue={[userProfileProps["g"]]} multiple={false} onChange={v => setGenderValue("g", v)} />
                </Card>
                <Card key="gender-interest" title="Interested in">
                  <Selector style={{ '--checked-color': '#ffe2e5' }}
                    options={Helper.findGenderOptions()} multiple={true} defaultValue={userProfileProps["gi"]} onChange={v => setFieldValueSelect("gi", v)} />
                </Card>

                <Card key="city" title="City">
                  <Input
                    placeholder='City'
                    defaultValue={userProfileProps["city"]}
                    onChange={v => setFieldValue("city", v)}
                  />
                </Card>


                <Card key="zipcode" title="Show Profiles From ZIP/Postal Codes">
                  <Select mode="tags" style={{ width: '100%' }} defaultValue={userProfileProps["zip"]} placeholder="ZIP/Postal Codes" onChange={(e) => changeZipCodes(e)}></Select>
                </Card>

                <Card key="hobbies" title="Hobbies">
                  <Selector style={{ '--checked-color': '#ffe2e5' }}
                    options={Helper.findHobbiesOptions()} multiple={true} defaultValue={userProfileProps["hobbies"]} onChange={v => setFieldValueSelect("hobbies", v)} />
                </Card>

                <div style={{ textAlign: "right" }}>
                  {profileUpdated ? (
                    <Button style={{ color: "#FFFFFF", backgroundColor: '#008000', border: '1px solid #008000' }} size='large' color='primary'> <i style={{ fontSize: 15 }} className="fas fa-check-double"></i><span>&#8195;SAVED</span> </Button>
                  ) : (
                    <Button style={{ color: "#FFFFFF", backgroundColor: '#FFA500', border: '1px solid #FFA500' }} size='large' color='primary' onClick={() => updateUserProfile()}> <i style={{ fontSize: 15 }} className="far fa-paper-plane"></i><span>&#8195;SAVE</span> </Button>
                  )}
                </div>
                
                <AutoCenter style={{ marginTop: 50 }}>
                  <Space>
                    <Button style={{ backgroundColor: '#0000FF', border: '1px solid #0000FF' }} size='large' color='primary' onClick={() => sendHomePage()}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fa fa-home"></i> </Button>
                    <Button style={{ backgroundColor: '#CC33FF', border: '1px solid #0000FF' }} size='large' color='primary' onClick={() => viewProfile()}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-external-link-alt"></i> </Button>
                  </Space>
                </AutoCenter>


                <div style={{ marginTop: 50}}>
                  <center>
                    <p>If you like to share your profile, please click the followings</p>
                    <div><Space>
                      <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><WhatsappShareButton url={'https://expertsq.techiehug.com/profile/' + userId}><i style={{ fontSize: 20, color:"#FFFFFF" }} className="fab fa-whatsapp"></i></WhatsappShareButton></div>
                      <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><FacebookShareButton url={'https://expertsq.techiehug.com/profile/' + userId}><i style={{ fontSize: 20, color:"#FFFFFF" }} className="fab fa-facebook-square"></i></FacebookShareButton></div>
                      <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><TwitterShareButton url={'https://expertsq.techiehug.com/profile/' + userId}><i style={{ fontSize: 20, color:"#FFFFFF" }} className="fab fa-twitter-square"></i></TwitterShareButton></div>
                      <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><TumblrShareButton url={'https://expertsq.techiehug.com/profile/' + userId}><i style={{ fontSize: 20, color:"#FFFFFF" }} className="fab fa-tumblr-square"></i></TumblrShareButton></div>
                    </Space></div>
                    <div style={{ marginTop: 10 }}><Space>
                      <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><TelegramShareButton url={'https://expertsq.techiehug.com/profile/' + userId}><i style={{ fontSize: 20, color:"#FFFFFF" }} className="fab fa-telegram"></i></TelegramShareButton></div>
                      <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><PinterestShareButton url={'https://expertsq.techiehug.com/profile/' + userId}><i style={{ fontSize: 20, color:"#FFFFFF" }} className="fab fa-pinterest-square"></i></PinterestShareButton></div>
                      <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><LinkedinShareButton url={'https://expertsq.techiehug.com/profile/' + userId}><i style={{ fontSize: 20, color:"#FFFFFF" }} className="fab fa-linkedin"></i></LinkedinShareButton></div>
                      <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><EmailShareButton url={'https://expertsq.techiehug.com/profile/' + userId}><i style={{ fontSize: 20, color:"#FFFFFF" }} className="far fa-envelope"></i></EmailShareButton></div>
                    </Space></div>
                  </center>
                </div>

                <div style={{ marginTop: 30 }}>
                  <center>
                    <p>Please copy this URL and share.</p>
                    <Input.Group compact>
                      <Input style={{ width: 'calc(100% - 200px)' }} defaultValue="https://expertsq.techiehug.com/profile/'+userId" />
                      <Tooltip title="URL is copied">
                        <CopyToClipboard text={'https://expertsq.techiehug.com/profile/' + userId}><Button icon={<i className="far fa-copy"></i>} ></Button></CopyToClipboard>
                      </Tooltip>
                    </Input.Group>
                  </center>
                </div>


              </div>

            ) : (
              <div style={{ fontSize: 24, marginTop: 100 }}>
                <AutoCenter>
                  <img width={500} src="loading-1.gif" />
                </AutoCenter>
              </div>
            )}

          </Content>
          <AppFooter />
        </Layout>
      </div>
    )

}

export default Profile;