import { useState, useEffect, React } from "react";
import firebaseApp from "./Firebase";
import { Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import { Image, Toast, Card, List, TextArea, AutoCenter } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import AppFooter from "./AppFooter"
import Helper from "./Helper"
import { useParams, useNavigate } from "react-router-dom";
import Moment from 'react-moment';


const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;


let count = 0;
let userId = "";

function ViewUserProfile() {
    let { profileId } = useParams();
    const pageNavigate = useNavigate();
    let [profileProps, setProfileProps] = useState({"hobbies":[]});

    useEffect(() => {
        Helper.openLoginPage();
        userId = localStorage.getItem("currentUserId");
        const findProfileByIdQuery = firebaseApp.findProfileById(profileId);
        showProfileById(findProfileByIdQuery);
    }, []);

    function showProfileById(docPromise) {
        docPromise.then((doc) => {
            if (!doc.exists) { return; }
            profileProps = doc.data();
            profileProps["hobbies"] = Helper.hobbiesDisplay(profileProps["hobbies"]);
            profileProps["gender"] = Helper.genderDisplay(profileProps["gender"]);
            profileProps["age"] = "NA";
            if (profileProps["year"] != undefined) {
                profileProps["age"] = (new Date().getFullYear() - profileProps["year"]);
            }
            console.log(profileProps);
            setProfileProps(profileProps);
        });
    }

    function sendBackPage()
    {
        pageNavigate(-1);
    }
    function sendHomePage() {
        window.location.href = "/"
    }


    function sendRequestByProfileId(){
        firebaseApp.sendRequestByProfileId({ "profile_id": profileId, "user_id": userId });
        Toast.show("Wink Sent Successfully");
    }

    function bookmarkByProfileId(){
        firebaseApp.bookmarkByProfileId({ "profile_id": profileId, "user_id": userId });
    }
    

    return (
        <div>
            <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                <Header style={{ backgroundColor: "#2f4f4f", paddingLeft: 0, position: 'fixed', zIndex: 1, width: '100%' }}>
                    <SideMenu />
                </Header>

                <Content style={{ marginTop: 80, marginLeft: 10, marginRight: 10, backgroundColor: "#FFFFFF" }}>

                    <Card key='user-profile' style={{ borderRadius: '0px', border: "1px solid teal", marginTop: 20 }}>
                        <div><Image src={profileProps["image"]} width="100%" fit='fill' /></div>
                    </Card>

                    <div><strong>Name:</strong> <span className="profileName">{profileProps["name"]}</span></div>
                    <div><strong>Age:</strong> {profileProps["age"]}</div>
                    {profileProps["desc"] && (<div><strong>About:</strong> {profileProps["desc"]}</div>)}
                    {profileProps["zip"] && (<div><strong>Location:</strong> {profileProps["zip"].join(", ")}</div>)}
                    {(profileProps["hobbies"].length!==0) && (<div><strong>Hobbies:</strong> {profileProps["hobbies"].join(", ")}</div>)}

                    <AutoCenter>
                        <Space style={{marginTop:30}}>
                            <Button style={{ backgroundColor: '#33CC33', border: '1px solid #33CC33' }}  size='large' color='primary' onClick={() => sendBackPage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="fas fa-backward"></i> </Button>
                            <Button style={{ backgroundColor: '#0000FF', border: '1px solid #0000FF' }} size='large' color='primary' onClick={() => sendRequestByProfileId(profileProps["id"])}> <i style={{fontSize:25, color:"#FFFFFF"}}  className="far fa-kiss-wink-heart"></i> </Button>
                            <Button style={{ backgroundColor: '#CC33FF', border: '1px solid #CC33FF' }} size='large' color='primary' onClick={() => bookmarkByProfileId(profileProps["id"])}> <i style={{fontSize:25, color:"#FFFFFF"}}  className="far fa-bookmark"></i> </Button>
                            <Button style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} size='large' color='primary' size='large' color='primary' onClick={() => sendHomePage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="fa fa-home"></i> </Button>
                        </Space>
                    </AutoCenter>

                </Content>
                <AppFooter />
            </Layout>
        </div>
    )

}
export default ViewUserProfile;