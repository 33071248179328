import { useState, useEffect, React } from "react";
import { Tooltip, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Space } from "antd";
import SideMenu from "./SideMenu";
import AppFooter from "./AppFooter"
import Helper from "./Helper"
import { EmailIcon, EmailShareButton, PinterestShareButton, LinkedinShareButton, TumblrShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getAuth, signOut } from "firebase/auth";

const { SubMenu } = Menu;
const { Header, Content } = Layout;

let userId = "";


function Application()
{
    function signOutTheUser(){
        signOut(getAuth()).then(() => {
          window.location.href = window.location.href;
        }).catch((error) => {
          window.location.href = window.location.href;
        });
      }
    
    useEffect(() => {
        Helper.openLoginPage();
        userId = localStorage.getItem("currentUserId");
      }, []);


        return (
            <div>
                <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                    <Header style={{ backgroundColor: "#2f4f4f", paddingLeft: 0, position: "fixed", zIndex: 1, width: "100%" }}>
                        <SideMenu />
                    </Header>
                    <Content style={{ marginTop: 50, backgroundColor: "#FFFFFF", marginTop: 60, marginRight: 20 }}>


                        <div style={{ marginTop: 30 }}>
                            <center>
                                <p>If you like us, please share</p>
                                <div>
                                    <Space>
                                        <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><WhatsappShareButton url={'https://expertsq.techiehug.com'}><i style={{ color:"#FFFFFF", fontSize: 20 }} className="fab fa-whatsapp"></i></WhatsappShareButton></div>
                                        <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><FacebookShareButton url={'https://expertsq.techiehug.com'}><i style={{ color:"#FFFFFF", fontSize: 20 }} className="fab fa-facebook-square"></i></FacebookShareButton></div>
                                        <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><TwitterShareButton url={'https://expertsq.techiehug.com'}><i style={{ color:"#FFFFFF", fontSize: 20 }} className="fab fa-twitter-square"></i></TwitterShareButton></div>
                                        <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><TumblrShareButton url={'https://expertsq.techiehug.com'}><i style={{ color:"#FFFFFF", fontSize: 20 }} className="fab fa-tumblr-square"></i></TumblrShareButton></div>
                                    </Space>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <Space>
                                        <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><TelegramShareButton url={'https://expertsq.techiehug.com'}><i style={{ color:"#FFFFFF", fontSize: 20 }} className="fab fa-telegram"></i></TelegramShareButton></div>
                                        <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><PinterestShareButton url={'https://expertsq.techiehug.com'}><i style={{ color:"#FFFFFF", fontSize: 20 }} className="fab fa-pinterest-square"></i></PinterestShareButton></div>
                                        <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><LinkedinShareButton url={'https://expertsq.techiehug.com'}><i style={{ color:"#FFFFFF", fontSize: 20 }} className="fab fa-linkedin"></i></LinkedinShareButton></div>
                                        <div type="primary" style={{ padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}><EmailShareButton url={'https://expertsq.techiehug.com'}><i style={{ color:"#FFFFFF", fontSize: 20 }} className="far fa-envelope"></i></EmailShareButton></div>
                                </Space></div>
                            </center>
                        </div>

                        <div style={{ marginTop: 30 }}>
                            <center>
                                <p>Please copy this URL and share.</p>
                                <Input.Group compact>
                                    <Input style={{ width: 'calc(100% - 200px)' }} defaultValue="https://expertsq.techiehug.com/" />
                                    <Tooltip title="URL is copied">
                                        <CopyToClipboard text={'https://expertsq.techiehug.com/'}><Button icon={<i className="far fa-copy"></i>} ></Button></CopyToClipboard>
                                    </Tooltip>
                                </Input.Group>
                            </center>
                        </div>

                        <div style={{ marginTop: 30 }}>
                            <center>
                                <p>For all your enquiries, please write us at</p>
                                <div type="primary"  onClick={(e) => window.location.href="/contact"} style={{ width:100, padding: "5px 10px 5px 10px", borderRadius: "3px", backgroundColor: "#FF661A" }}>Contact Us</div>
                            </center>
                        </div>

                        <div style={{ marginTop: 30 }}>
                            <center>
                                <p>For all your enquiries, please write us at</p>
                                <Button style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} onClick={(e) => signOutTheUser(e)} ><i className="fas fa-power-off"></i>&#x2003;Logout</Button>
                            </center>
                        </div>



                    </Content>
                    <AppFooter />
                </Layout>
            </div>
        )
}

export default Application;