import { useState, useEffect, React } from "react";
import firebaseApp from "./Firebase";
import { Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import { Image, Grid, Card, TextArea, AutoCenter } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import AppFooter from "./AppFooter"
import Helper from "./Helper"
import { useParams, useNavigate } from "react-router-dom";
import Moment from 'react-moment';

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;


let count = 0;
let userId = "";
let userMessageProps = {};

function ChatHistory() {
    let { profileId } = useParams();
    const pageNavigate = useNavigate();
    let [chatHistory, setChatHistory] = useState([]);


    useEffect(() => {
        Helper.openLoginPage();
        userId = localStorage.getItem("currentUserId");

        const findChatHistoryQuery = firebaseApp.findUserLastChatByUserId(userId);
        displayChatHistory(findChatHistoryQuery);

    }, []);

    function displayChatHistory(queryPromise) {
        queryPromise.then((querySnapshot) => {
            let chatHistory = [];
            querySnapshot.forEach((doc) => {
                let chatProps = doc.data();
                chatProps["id"] = doc.id;
                let mateIds = [chatProps["user_id"], chatProps["profile_id"]].sort();
                chatProps["mate_id"] = mateIds[0] + "-" + mateIds[1];
                chatHistory.push(chatProps);
                console.log(doc);
            });
            chatHistory.reverse();
            setChatHistory(chatHistory);

        });
    }

    function sendBackPage() {
        pageNavigate(-1);
    }


    return (
        <div>
            <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                <Header style={{ backgroundColor: "#2f4f4f", paddingLeft: 0, position: 'fixed', zIndex: 1, width: '100%' }}>
                    <SideMenu />
                </Header>

                <Content style={{ marginTop: 80, marginLeft: 10, marginRight: 10, backgroundColor: "#FFFFFF" }}>


                    <div>
                        {chatHistory.map((item, i) => (
                            <div style={item["style"]} key={i}>
                                <Grid columns={4} gap={8}>
                                    <Grid.Item onClick={(e) => window.location.href="/profile/"+item["user_id"]}>
                                        <Image
                                            src={"https://storage.googleapis.com/expertsq-public-files/profiles/default-profile.jpeg"}
                                            width={80} height={80} fit='cover' style={{ borderRadius: 8 }}
                                        />
                                    </Grid.Item>
                                    <Grid.Item span={3}>
                                        <div>{item["desc"]}<br />
                                            <small><i className="far fa-clock"></i> <Moment>{item["created_at"].toDate()}</Moment></small>
                                        </div>
                                        <div>
                                            <Space size={15}>
                                                <Button style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} onClick={(e) => window.location.href="/chatmate/"+item["mate_id"]}><i style={{fontSize:25, color:"#FFFFFF"}} className="far fa-comment-dots"></i></Button>
                                                <Button style={{ backgroundColor: '#CC33FF', border: '1px solid #CC33FF' }} onClick={(e) => window.location.href="/profile/"+item["user_id"]}><i style={{fontSize:25, color:"#FFFFFF"}} className="fas fa-info-circle"></i></Button>
                                            </Space>
                                        </div>
                                    </Grid.Item>
                                </Grid>

                            </div>
                        ))}
                    </div>

                    <div style={{ marginTop: 15 }}>
                        <AutoCenter>
                            <Space style={{marginTop:30}}>
                            <Button style={{ backgroundColor: '#33CC33', border: '1px solid #33CC33' }}  size='large' color='primary' onClick={() => sendBackPage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="fas fa-backward"></i> </Button>
                                <Button style={{ backgroundColor: '#cccc00', border: '1px solid #cccc00' }} size='large' color='primary' size='large' color='primary' onClick={() => Helper.goHomePage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="fa fa-home"></i> </Button>
                                <Button style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} size='large' color='primary' size='large' color='primary' onClick={() => Helper.goInboxPage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="far fa-envelope"></i> </Button>
                                <Button style={{ backgroundColor: '#00ff00', border: '1px solid #00ff00' }} size='large' color='primary' size='large' color='primary' onClick={() => Helper.goSentPage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="far fa-paper-plane"></i> </Button>
                            </Space>
                        </AutoCenter>
                    </div>

                </Content>
                <AppFooter />
            </Layout>
        </div>
    )
}
export default ChatHistory;