import { initializeApp } from "firebase/app";
import { increment, getFirestore, collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, orderBy, limit, updateDoc, deleteDoc, documentId, serverTimestamp } from "firebase/firestore";
import { getAuth, onAuthStateChanged} from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";


const settings = {timestampsInSnapshots: true};


const prodFirebaseConfig = {
  apiKey: "AIzaSyBIlnO4t_dVcHRO3OTq9GIj2BDvIntCdv4",
  authDomain: "expertsq-74d59.firebaseapp.com",
  projectId: "expertsq-74d59",
  storageBucket: "expertsq-74d59.appspot.com",
  messagingSenderId: "713954174989",
  appId: "1:713954174989:web:65e47e2d525d145a880d44",
  measurementId: "G-Z4TDZZH0H7"
};

// Initialize Firebase
const currentAppConfig = prodFirebaseConfig;
const currentProjectId = currentAppConfig["projectId"];
const firebaseApplication = initializeApp(currentAppConfig);
const firestoreApp = getFirestore(firebaseApplication);
const firebasePublicStorage = getStorage(firebaseApplication, "gs://expertsq-public-files");
const firebaseStorage = getStorage();
const firebaseAuthApp = getAuth(firebaseApplication);
//firebase.firestore().settings(settings);



const userProfilesTable = collection(firestoreApp, "user_profiles");
const categoriesRef = collection(firestoreApp, "categories");
const storesRef = collection(firestoreApp, "stores");
const userStoresRef = collection(firestoreApp, "user_stores");
const userOrdersRef = collection(firestoreApp, "user_orders");
const storeUsersRef = collection(firestoreApp, "store_users");
const storeCategoriesRef = collection(firestoreApp, "store_categories");
const userAddressRef = collection(firestoreApp, "user_address");
const userConversionsRef = collection(firestoreApp, "user_conversions");
const userBookmarksTable = collection(firestoreApp, "user_bookmarks");
const sentInterestsTable = collection(firestoreApp, "sent_interests");
const userMatesTable = collection(firestoreApp, "user_mates");
const userChatsTable = collection(firestoreApp, "user_chats");
const userLastChatsTable = collection(firestoreApp, "user_last_chats");
const mateChatsTable = collection(firestoreApp, "mate_chats");
const userEmailsTable = collection(firestoreApp, "user_emails");


export default class firebaseApp {

  constructor() {
    console.log("Firebase initialized");
  }

  static findProjectId()
  {
    return currentProjectId;
  }

  //firebase.firestore().collection("user_carts").doc(selectedProduct["cart_id"]).delete();
  static deleteUserCartById = async(cartId) => {
    await deleteDoc(doc(firestoreApp, "user_carts", cartId));
  }


  static findAllProfiles = async(recordLength) => {
    const firebaseQuery = query(userProfilesTable, where("active", "==", 1), orderBy("created_at", "desc"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }

  static findProfilesByPreference = async(searchProps, recordLength) => {
    const firebaseQuery = query(userProfilesTable, where("active", "==", 1), where("search", "array-contains-any", searchProps), orderBy("created_at", "desc"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }

  static findProfilesBySearch = async(searchProps, recordLength) => {
    const firebaseQuery = query(userProfilesTable, where("active", "==", 1), where("search", "array-contains-any", searchProps), orderBy("created_at", "desc"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }


  static showProfilesByProfileId = async(profileIds) => {
    if(profileIds.length == 0 ) { return []; }
    const firebaseQuery = query(userProfilesTable, where(documentId(), "in", profileIds), limit(10));
    return await getDocs(firebaseQuery);
  }
  

  static findInterestReceived = async(userId) => {
    const firebaseQuery = query(sentInterestsTable, where("profile_id", "==", userId), where("mate_id", "==", "NA"), orderBy("created_at", "desc"), limit(10));
    return await getDocs(firebaseQuery);
  }

  static findInterestSent = async(userId) => {
    const firebaseQuery = query(sentInterestsTable, where("user_id", "==", userId), where("mate_id", "==", "NA"), orderBy("created_at", "desc"), limit(10));
    return await getDocs(firebaseQuery);
  }

  static findAllBookmarks = async(userId) => {
    const firebaseQuery = query(userBookmarksTable, where("user_id", "==", userId), orderBy("created_at", "desc"), limit(10));
    return await getDocs(firebaseQuery);
  }

  static findAllMatesByUerId = async(userId) => {
    const firebaseQuery = query(userMatesTable, where("mates", "array-contains", userId), limit(10));
    return await getDocs(firebaseQuery);
  }

  static findAllCategories = async(recordLength) => {
    const firebaseQuery = query(categoriesRef, where("show","==",1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }

  static findStoreCategories = async(storeId, recordLength) => {
    const firebaseQuery = query(storeCategoriesRef, where("show","==",storeId), where("show","==",1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }

  static findChatHistory = async(userId, profileId) => {
    const firebaseQuery = query(userChatsTable, where("user_id","==",userId), where("profile_id","==",profileId), orderBy("created_at","desc"), limit(10));
    return await getDocs(firebaseQuery);
  }

  static findChatHistoryByMateId = async(mateId) => {
    const firebaseQuery = query(mateChatsTable, where("mate_id","==",mateId), orderBy("created_at","desc"), limit(10));
    return await getDocs(firebaseQuery);
  }


  static sendEmailMessage = async(docData) => {
    docData = this.newRecordDefaults(docData)
    await addDoc(collection(firestoreApp, "user_emails"), docData);
  }

  static findEmailsByUserId = async(userId) => {
    const firebaseQuery = query(userEmailsTable, where("user_id","==",userId), where("type","==",0), orderBy("created_at","desc"), limit(50));
    return await getDocs(firebaseQuery);
  }

  static findEmailsByCorresId = async(corresId) => {
    const firebaseQuery = query(userEmailsTable, where("corres_id","==",corresId), where("type","==",1), orderBy("created_at","desc"), limit(50));
    return await getDocs(firebaseQuery);
  }



  static findChatHistoryByUser = async(userId) => {
    const firebaseQuery = query(userChatsTable, where("user_id","==",userId), orderBy("created_at","desc"), limit(50));
    return await getDocs(firebaseQuery);
  }

  static findUserLastChatByUserId = async(userId) => {
    const firebaseQuery = query(userLastChatsTable, where("profile_id","==",userId), orderBy("created_at","desc"), limit(50));
    return await getDocs(firebaseQuery);
  }


  
  static findProfileById = async(profileId) => {
    const firebaseQuery = query(doc(firestoreApp, "user_profiles", profileId));
    return await getDoc(firebaseQuery);
  }

  static findMatesIdByMateId = async(mateId) => {
    const firebaseQuery = query(doc(firestoreApp, "user_mates", mateId));
    return await getDoc(firebaseQuery);
  }
  
  static findProductsByStoreIdAndCategoryId = async(storeId, categoryId, length) => {
    const firebaseQuery = query(userProfilesTable, where("store_id","==",storeId), where("category_id", "array-contains", categoryId), where("show","==",1), orderBy("ord"), limit(length));
    return await getDocs(firebaseQuery);
  }

  static findProductsByStoreId = async(storeId) => {
    const firebaseQuery = query(userProfilesTable, where("store_id","==",storeId), where("show","==",1), orderBy("ord"), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findProductsByIdIn = async(findProductsByIdsIn) => {
    if(findProductsByIdsIn.length == 0 ) { return []; }
    const firebaseQuery = query(userProfilesTable, where(documentId(),"in",findProductsByIdsIn));
    return await getDocs(firebaseQuery);
  }

  static findAllStores = async(recordLength) => {
    const firebaseQuery = query(storesRef, where("show","==",1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }
  



    
  static findUserStoresByUserId = async(userId) => {
    const firebaseQuery = query(userStoresRef, where("user_id","==", userId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findOwnStoresByUserId = async(userId) => {
    console.log(userId);
    const firebaseQuery = query(storeUsersRef, where("user_id","==",userId), limit(10));
    return await getDocs(firebaseQuery);
  }
  

  static findStoreById = async(storeId) => {
    const firebaseQuery = query(doc(firestoreApp, "stores", storeId));
    return await getDoc(firebaseQuery);
  }

  static findStoreOrderByStoreId = async(storeId) => {
    const firebaseQuery = query(userOrdersRef, where("store_id","==",storeId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoresByPincode = async(pincode) => {
    const firebaseQuery = query(storesRef, where("pincode","==",pincode), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoresByPincodeAndAreaName = async(pincode, areaName) => {
    const firebaseQuery = query(storesRef, where("pincode","==",pincode), where("area_name","==",areaName), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoresByAreaName = async(areaName) => {
    const firebaseQuery = query(storesRef, where("area_name","==",areaName), limit(100));
    return await getDocs(firebaseQuery);
  }

  

  static findUserOrderByUserId = async(userId) => {
    const firebaseQuery = query(userOrdersRef, where("user_id","==",userId), orderBy("created_at", "desc"), limit(100));
    return await getDocs(firebaseQuery);
  }
  
  
  static findStoresByIdIn = async(storeIds) => {
    console.log("storeIds",storeIds);
    const firebaseQuery = query(storesRef, where(documentId(),"in",storeIds), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findUserAddressByIds = async(addressIds) => {
    if(addressIds.length==0) return [];
    const firebaseQuery = query(userAddressRef, where(documentId(),"in",addressIds), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStores = async() => {
    const firebaseQuery = query(storesRef, where("show","==",1), orderBy("store_name"), limit(1000));
    return await getDocs(firebaseQuery);
  }


  static findServerTime = () => {
    return serverTimestamp();
  }


  static getCartById = async(cartId) => {
    const firebaseQuery = query(doc(firestoreApp, "user_carts", cartId));
    return await getDoc(firebaseQuery);
  }

  static updateCartById = async(docId, docData) => {
    await updateDoc(doc(firestoreApp, "user_carts", docId), docData); 
  }

  static addUserProfile = async(userId) => {
    let docData = this.newRecordDefaults({});
    await setDoc(doc(firestoreApp, "user_profiles", userId), docData, { merge: true });
  }

  static sendRequestByProfileId = async(docData) => {
    let docId = docData["user_id"] + "-" + docData["profile_id"];
    docData = this.newRecordDefaults(docData);
    docData["mate_id"] = "NA";
    await setDoc(doc(firestoreApp, "sent_interests", docId), docData, { merge: true }); 
  }

  static updateUserProfile = async(userId, docData) => {
    docData = this.newRecordDefaults(docData);
    console.log(docData);
    docData["count"] = increment(1)
    await setDoc(doc(firestoreApp, "user_profiles", userId), docData, { merge: true }); 
  }

  static addNewUserProfile = async(userId, docData) => {
    //check whether the user exist already if not set the defaults
    const firebaseQuery = query(doc(firestoreApp, "user_profiles", userId));
    const newUserCreationPromise = await getDoc(firebaseQuery);
    if(newUserCreationPromise.exists)
    {
      docData = this.updateRecordDefaults(docData);
      await setDoc(doc(firestoreApp, "user_profiles", userId), docData, { merge: true }); 
    }
    else
    {
      docData = this.newRecordDefaults(docData);
      docData["login"] = 1;
      docData["gi"] = [1, 2, 3];
      docData["hobbies"] = [25, 29];
      docData["search"] = ["HOBBI25", "HOBBI29", "GI1","GI2","GI3"];
      await setDoc(doc(firestoreApp, "user_profiles", userId), docData, { merge: true }); 
    }
    await addDoc(collection(firestoreApp, "user_login_logs"), docData);    
  }

  static bookmarkByProfileId = async(docData) => {
    let docId = docData["user_id"] + "-" + docData["profile_id"];
    docData = this.newRecordDefaults(docData);
    await setDoc(doc(firestoreApp, "user_bookmarks", docId), docData); 
  }

  static removeMateByProfileId = async(docData) => {
    let docId = docData["user_id"] + "-" + docData["profile_id"];
    docData = this.newRecordDefaults(docData);
    await setDoc(doc(firestoreApp, "break_user_mates", docId), docData); 
  }

  static blockProfileById = async(docData) => {
    let docId = docData["user_id"] + "-" + docData["profile_id"];
    docData = this.newRecordDefaults(docData);
    await setDoc(doc(firestoreApp, "blocked_users", docId), docData); 
  }

  
  //todo remove other requests of these mates
  static acceptRequestByProfileId = async(docData) => {
    let mateIds = [docData["user_id"], docData["profile_id"]];
    mateIds = mateIds.sort();
    let mateId = mateIds[0] + "-" + mateIds[1];

    let requestId = docData["user_id"] + "-" + docData["profile_id"];
    const firebaseQuery = query(doc(firestoreApp, "sent_interests", requestId));
    const selfPromise = await getDoc(firebaseQuery);
    if(selfPromise.exists)
    {
      setDoc(doc(firestoreApp, "sent_interests", selfPromise.id), {"mate_id": mateId}, { merge: true });
    }

    let requestReverseId = docData["profile_id"] + "-" + docData["user_id"];
    const firebaseReverseQuery = query(doc(firestoreApp, "sent_interests", requestReverseId));
    const selfReversePromise = await getDoc(firebaseReverseQuery);
    if(selfReversePromise.exists)
    {
      setDoc(doc(firestoreApp, "sent_interests", selfReversePromise.id), {"mate_id": mateId}, { merge: true });
    }

    docData["mates"] = mateIds;
    docData["mate_id"] = mateId;
    docData = this.newRecordDefaults(docData);
    await setDoc(doc(firestoreApp, "user_mates", mateId), docData);
  }



  static updateRecordDefaults = (docData) =>
  {
    docData["updated_at"] = serverTimestamp();
    docData["bot"] = 0;
    docData["count"] = increment(1)
    return docData;
  }

  static newRecordDefaults = (docData) =>
  {
    docData["created_at"] = serverTimestamp();
    docData["updated_at"] = serverTimestamp();
    docData["bot"] = 0;
    return docData;
  }

  static saveUserAddress = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_address", docId), docData); 
  }

  static updateUserCartsByStore = async(docId, docData) => {
    console.log(docId);
    console.log(docData);
    //await updateDoc(doc(firestoreApp, "user_carts", docId), docData); 
  }


  static saveUserOrder = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_orders", docId), docData); 
  }

  static updateUserOrder = async(docId, docData) => {
    await updateDoc(doc(firestoreApp, "user_orders", docId), docData); 
  }

  static updateUserOrderStatus = async(docId, docData) => {
    await updateDoc(doc(firestoreApp, "user_order_status", docId), docData); 
  }

  static saveUserOrderStatus = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_order_status", docId), docData); 
  }

  static saveUserStores = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_stores", docId), docData); 
  }

  static bookmarkProductByUser = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_product_bookmarks", docId), docData); 
  }
  static removeBookmarkById = async(docId) => {
    await deleteDoc(doc(firestoreApp, "user_bookmarks", docId));
  }

  static removeRequestById = async(docId) => {
    await deleteDoc(doc(firestoreApp, "sent_interests", docId));
  }

  static updateUserStores = async(docId, docData) => {
    await setDoc(doc(firestoreApp, "user_stores", docId), docData); 
  }
  static deleteUserStoreById = async(storeId) => {
    await deleteDoc(doc(firestoreApp, "user_stores", storeId));
  }

  static findUserAddress = async(userId) => {
    const firebaseQuery = query(userAddressRef, where("user_id","==",userId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static deleteUserAddressById = async(addressId) => {
    await deleteDoc(doc(firestoreApp, "user_address", addressId));
  }

  static assignAnonymousUserWithAuthUser = async(anonymousUserId, authUserId) => {
    await setDoc(doc(userConversionsRef), {"anonymous_user_id": anonymousUserId, "auth_user_id": authUserId, "created_at": serverTimestamp()}); 
  }


  static sendChatMessage = async(docData) => {
    docData = this.newRecordDefaults(docData)
    await addDoc(collection(firestoreApp, "user_chats"), docData);
    //write the last message for chat page
    const docId = docData["profile_id"] + "-" + docData["user_id"]
    await setDoc(doc(firestoreApp, "user_last_chats", docId), docData); 
  }

  static sendMateChatMessage = async(docData) => {
    docData = this.newRecordDefaults(docData)
    await addDoc(collection(firestoreApp, "mate_chats"), docData);
    //write the last message for chat page
    const docId = docData["profile_id"] + "-" + docData["user_id"]
    await setDoc(doc(firestoreApp, "user_last_chats", docId), docData); 
  }


  static uploadProfile = async(fileObject, userId) => {
    let fileName = userId + "." + fileObject.name.split('.').pop();
    const mountainImagesRef = ref(firebasePublicStorage, "profiles/"+fileName);
    await uploadBytes(mountainImagesRef, fileObject).then((snapshot) => {
      console.log("uploaded 1");
      console.log(snapshot);
    },
    (error) => {
      console.log(error.code);
    })
    console.log("uploaded 2");
    const finalUrl = "https://storage.googleapis.com/expertsq-public-files/profiles/" + fileName;
    this.updateUserProfile(userId, {"image": finalUrl});
    return {"url": finalUrl};
  }
 

  static sendChatMessage = async(docData) => {
    docData = this.newRecordDefaults(docData)
    await addDoc(collection(firestoreApp, "user_chats"), docData);
  }
  


}