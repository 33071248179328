import { useState, useEffect, React } from "react";
import firebaseApp from "./Firebase";
import { Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import { Image, Grid, Card, List, TextArea, AutoCenter } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import AppFooter from "./AppFooter"
import Helper from "./Helper"
import { useParams, useNavigate } from "react-router-dom";
import Moment from 'react-moment';
import { getDatabase, ref, onValue, onChildChanged, set } from "firebase/database";

const { SubMenu } = Menu;
const { Header, Footer, Sider, Content } = Layout;
const realtimeDatabase = getDatabase();


let count = 0;
let userId = "";
let userMessageProps = {};
let profileIdFound = "";
const chatHistoryReceived = [];
const chatStyle = {
    "sent":{ borderRadius:5, marginTop:5, padding:"5px 5px 0px 5px", backgroundColor: "#E6fff2", "textAlign": "right" },
    "received":{ borderRadius:5, marginTop:5, padding:"5px 5px 0px 5px", border:"1px solid yello", backgroundColor: "#FFFFFF", "textAlign": "left" }
}


function ChatWithMate() {
    let { profileId, mateId } = useParams();
    const pageNavigate = useNavigate();
    let [profileProps, setProfileProps] = useState({});
    let [defaultMessage, setChatMessage] = useState("");
    let [chatHistory, setChatHistory] = useState([]);


    useEffect(() => {
        Helper.openLoginPage();
        userId = localStorage.getItem("currentUserId");
        console.log("profileId", profileId);
        console.log("mateId", mateId);

        if(profileId!==undefined)
        {
            profileIdFound = profileId;
            const findProfileByIdQuery = firebaseApp.findProfileById(profileId);
            showProfileById(findProfileByIdQuery);
            
            const findChatHistoryQuery = firebaseApp.findChatHistory(userId, profileId);
            displayChatHistory(findChatHistoryQuery);

            let mateIds = [userId, profileId].sort();

            const userChatRef = ref(realtimeDatabase, 'user_chats/' + mateIds[0] + "-" + mateIds[1] + "/1");
            onValue(userChatRef, (snapshot) => {
                console.log(snapshot.val());
                const childData = snapshot.val();
                if(childData==null) return;
                childData["style"] = "received";
                if (childData["user_id"] == userId) childData["style"] = "sent";
                chatHistoryReceived.push(childData);
                setChatHistory(chatHistoryReceived);
            }, { onlyOnce: false });
        }

        if(mateId!==undefined)
        {
            const findProfileIdByMateIdQuery = firebaseApp.findMatesIdByMateId(mateId);
            processMatesByMateId(findProfileIdByMateIdQuery);

            const findChatHistoryByMateIdQuery = firebaseApp.findChatHistoryByMateId(mateId);
            displayChatHistory(findChatHistoryByMateIdQuery);

            const userChatRef = ref(realtimeDatabase, 'mate_chats/' + mateId + "/1");
            onValue(userChatRef, (snapshot) => {
                const childData = snapshot.val();
                if(childData==null) return;
                console.log(childData);
                childData["style"] = "received";
                if (childData["user_id"] == userId) childData["style"] = "sent";
                chatHistoryReceived.push(childData);
                setChatHistory(chatHistoryReceived);
            }, { onlyOnce: false });
        }



    }, []);

    function processMatesByMateId(docPromise) {
        docPromise.then((doc) => {
            if (!doc.exists) { return; }
            profileProps = doc.data();
            console.log(profileProps);
            if(profileProps==undefined) return;
            let profileIds = profileProps["mates"];
            profileIds = profileIds.filter(item => item !== userId);
            userMessageProps["profile_id"] = profileIds[0];
            profileIdFound = profileIds[0];
            const findProfileByIdQuery = firebaseApp.findProfileById(profileIdFound);
            showProfileById(findProfileByIdQuery);
        });
    }

    function showProfileById(docPromise) {
        docPromise.then((doc) => {
            if (!doc.exists) { return; }
            profileProps = doc.data();
            console.log(profileProps);
            setProfileProps(profileProps);
        });
    }

    function displayChatHistory(queryPromise) {
        queryPromise.then((querySnapshot) => {
            console.log("displayChatHistory");
            querySnapshot.forEach((doc) => {
                let chatProps = doc.data();
                chatProps["style"] = "received";
                if (chatProps["user_id"] == userId) chatProps["style"] = "sent";
                chatProps["created_at"] = chatProps["created_at"].toDate();
                chatHistoryReceived.push(chatProps);
            });
            chatHistoryReceived.reverse();
            setChatHistory(chatHistoryReceived);
        });
    }


    function sendBackPage() {
        pageNavigate(-1);
    }
    function sendHomePage() {
        window.location.href = "/"
    }

    function setFildValue(fieldName, e) {
        console.log(fieldName, e);
        userMessageProps[fieldName] = e;
        setChatMessage(e);
    }

    function sendChatMessage() {
        if(userMessageProps["desc"]==undefined) { return; }
        if(userMessageProps["desc"].length==0) { return; }
        userMessageProps["user_id"] = userId;
        userMessageProps["profile_id"] = profileIdFound;
        if(mateId!==undefined)
        {
            userMessageProps["mate_id"] = mateId;
            firebaseApp.sendMateChatMessage(userMessageProps);
            set(ref(realtimeDatabase, 'mate_chats/' + mateId + "/1"), userMessageProps);
        }
        else
        {
            let mateIds = [userId, profileId].sort();
            firebaseApp.sendChatMessage(userMessageProps);
            set(ref(realtimeDatabase, 'user_chats/' + mateIds[0] + "-" + mateIds[1] + "/1"), userMessageProps);
        }
        setChatMessage("");
    }

    return (
        <div>
            <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                <Header style={{ backgroundColor: "#2f4f4f", paddingLeft: 0, position: 'fixed', zIndex: 1, width: '100%' }}>
                    <SideMenu />
                </Header>

                <Content style={{ marginTop: 80, marginLeft: 10, marginRight: 10, backgroundColor: "#FFFFFF" }}>

                    <Grid columns={4} gap={8}>
                        <Grid.Item>
                            <Image
                                src={profileProps["image"]}
                                width={64}
                                height={64}
                                fit='cover'
                                style={{ borderRadius: 8 }}
                            />
                        </Grid.Item>
                        <Grid.Item span={3}>
                            <h1>{profileProps["name"]}</h1>
                            <h1>{profileProps["age"]}</h1>
                        </Grid.Item>
                    </Grid>

                    <div style={{backgroundColor:"#D3D3D3", padding:"10px 10px 10px 10px"}}>
                        {chatHistory.map((item, i) => (
                            <div style={chatStyle[item["style"]]} key={i}>
                                <p>{item["desc"]}<br /> <small><Moment>{item["created_at"]}</Moment></small>
                                </p>
                            </div>
                        ))}
                    </div>

                    <Card key="message" style={{ marginTop:10, border: "1px solid gray", padding: "10 10 10 10" }}>
                        <TextArea
                            placeholder='Write your message'
                            value={defaultMessage}
                            onChange={v => setFildValue("desc", v)}
                            maxLength={500}
                            autoSize={true}
                        />
                    </Card>

                    <div style={{ marginTop: 5, textAlign: "right" }}>
                        <Button style={{ color:"#FFFFFF", backgroundColor: '#FFA500', border: '1px solid #FFA500' }} size='large' color='primary' onClick={() => sendChatMessage()}> <i style={{fontSize:15}} className="far fa-paper-plane"></i><span>&#8195;SEND</span> </Button>
                    </div>


                    <AutoCenter>
                        <Space style={{marginTop:30}}>
                            <Button style={{ backgroundColor: '#33CC33', border: '1px solid #33CC33' }}  size='large' color='primary' onClick={() => sendBackPage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="fas fa-backward"></i> </Button>
                            <Button style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} size='large' color='primary' size='large' color='primary' onClick={() => sendHomePage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="fa fa-home"></i> </Button>
                        </Space>
                    </AutoCenter>



                </Content>
                <AppFooter />
            </Layout>
        </div>
    )

}
export default ChatWithMate;