import React from "react";
import SideMenu from "./SideMenu";
import { Divider, Row, Col, Card, Typography, Input, Layout, Button } from "antd";
import AppFooter from "./AppFooter"
import { useParams, useNavigate } from "react-router-dom";
const { Header, Content } = Layout;



function Extras() {

    let { extraPage } = useParams();
    console.log(extraPage);

    return (
        <div>
            <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                <Header style={{position: 'fixed', zIndex: 1, width: '100%',  backgroundColor:"#2f4f4f", paddingLeft: 0 }}>
                    <SideMenu />
                </Header>

                <Content className="longPara" style={{ marginTop:"65px", marginLeft:"20px", marginRight:"20px" }}>
                    <div style={{"display":((extraPage=="aboutus") ? "block" : "none")}} className="intromessage">
                    <h1>ABOUT US</h1>
                        <p>We are part of a technology company, and we are aiming to provide a social networking platform 100% free with all possible features, always.</p>
                        <p>We appreciate our users to provide any feedback using teh <a href={window.location.origin + "/contact"}>Contact us</a> page. We respect all your feedback and improve the system for the better community.</p>
                    </div>



                    <div style={{"display":((extraPage=="termsandcondition") ? "block" : "none")}} className="intromessage">
                    <h1>TERMS AND CONDITIONS</h1>
                    <p>This platform is 100% free. Hence, please do not pay anyone.</p>                    
                    <p>This website is operated by ExpertsQ. Throughout the site, the terms “we”, “us” and “our” refer to ExpertsQ. ExpertsQ offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
                    <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
                    <p>The user is responsible for any  unlawful full content posted by the user. The application is not responsible any unlawful activities by users.</p>

                    <h2>GENERAL CONDITIONS</h2>
                    <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
                    
                    <h2>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h2>
                    <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</p>
                    <h2>PERSONAL INFORMATION</h2>
                    <p>Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.</p>
                    <h2>PROHIBITED USES</h2>
                    <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
                    <h2>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
                    <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.</p>
                    <p>We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</p>
                    <p>You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.</p>
                    <p>You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
                    <p>In no case shall ExpertsQ, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>
                    <h2>INDEMNIFICATION</h2>
                    <p>You agree to indemnify, defend and hold harmless ExpertsQ and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
                    <h2>SEVERABILITY</h2>
                    <p>In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
                    <h2>TERMINATION</h2>
                    <p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>
                    <p>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.</p>
                    <p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p>
                    <h2>ENTIRE AGREEMENT</h2>
                    <p>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</p>
                    <p>These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</p>
                    <p>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</p>
                    <h2>CHANGES TO TERMS OF SERVICE</h2>
                    <p>You can review the most current version of the Terms of Service at any time at this page.</p>
                    <p>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
                    
                    <h2>CONTACT INFORMATION</h2>
                    <p>Questions about the Terms of Service should be sent to us at expertsq@techiehug.com</p>
                    </div>





                    <div style={{"display":((extraPage=="privacy") ? "block" : "none")}} className="intromessage">
                    <h1>PRIVACY</h1>

                    <h2>WHAT DO WE DO WITH YOUR INFORMATION?</h2>
                    <p>We never share your contact details with anyone.</p>
                    <p>We will never send any sms/emails.</p>
                    
                    <h2>DISCLOSURE</h2>
                    <p>We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.</p>

                    <h2>Google Firebase</h2>
                    <p>Our service is hosted on Google's platform, with all security compliances.</p>

                    <h2>Payment</h2>
                    <p>Our application is 100% free. We do not ask any fee, or payment related details. Please do not trust if anyone ask money, or money related details.</p>

                    <h2>Links</h2>
                    <p>When you click on links on our app, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>

                    <h2>SECURITY</h2>
                    <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>
                    <p>You should not share any of your financial details, credit-card and any money related details.</p>

                    <h2>AGE OF CONSENT</h2>
                    <p>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
                    </div>


                    <div style={{"display":((extraPage=="contactus") ? "block" : "none")}} className="intromessage">
                    <h1>CONTACT US</h1>
                    <p>Hi, For all enquires, please write us at <a href="mailto:expertsq@techiehug.com">expertsq@techiehug.com</a></p>
                    </div>
                    

                </Content>
                <AppFooter/>
            </Layout>
        </div>
    );

}
export default Extras;