import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./App.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import App from "./App";
import Bookmarks from "./Bookmarks";
import ViewUserProfile from "./ViewUserProfile"
import Profile from "./Profile";
import Application from "./Application";
import ChatWithMate from "./ChatWithMate";
import UserMates from "./UserMates";
import InterestReceived from "./InterestReceived";
import ChatHistory from "./ChatHistory"
import Extras from "./Extras"
import ContactPage from "./ContactPage"
import InterestSent from "./InterestSent";
import Login from "./Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  <BrowserRouter basename="/">
    <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<Login />} />
        <Route path="/offer/:offerid" element={<Login />} />
        <Route path="/interest-sent" element={<InterestSent />} />
        <Route path="/interest-received" element={<InterestReceived />} />
        <Route path="/bookmarks" element={<Bookmarks />} />
        <Route path="/myprofile" element={<Profile />} />
        <Route path="/mymates" element={<UserMates />} />
        <Route path="/chat/:profileId" element={<ChatWithMate />} />
        <Route path="/chatmate/:mateId" element={<ChatWithMate />} />
        <Route path="/chat-history" element={<ChatHistory />} />
        <Route path="/profile/:profileId" element={<ViewUserProfile />} />
        <Route path='/static/:extraPage' element={<Extras/>} />
        <Route path='/static/:extraPage' element={<Extras/>} />
        <Route path='/static/:extraPage' element={<Extras/>} />
        <Route path='/static/:extraPage' element={<Extras/>} />
        <Route path='/static/application' element={<Application/>} />
        <Route path='/contact' element={<ContactPage/>} />
        <Route path='/contact/:corresId' element={<ContactPage/>} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
